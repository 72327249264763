<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
          />
        </v-col>
        <v-col md="12">
          <v-data-table
            :items="allUser.results"
            :headers="headers"
            :server-items-length="allUser.pagination.total"
            :options.sync="options"
            :loading="loading"
          >
            <template v-slot:item.name="{ item }">
              {{ item.firstName }} {{ item.lastName }}
            </template>
          </v-data-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "User",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "User List",
    },
    loading: true,
    headers: [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Role', value: 'role', sortable: false },
    ],
    options: {
      itemsPerPage: 5,
      page: 1
    }
  }),
  computed: {
    ...mapGetters(["allUser"]),
  },
  methods: {
    ...mapActions(["getUsers"]),
    async reloadUsers() {
      this.loading = true;
      await this.getUsers({
        limit: this.options.itemsPerPage,
        skip: (this.options.page - 1) * this.options.itemsPerPage,
      });
      this.loading = false;
    },
  },
  created() {
    this.reloadUsers();
  },
};
</script>